<template>
  <div class="job-info-main-page">
    <el-form class="zwx-form" key="msForm" ref="msForm" :model="msForm" label-position="right" @submit.native.prevent>
      <div class="self-adapting-condition">
        <el-form-item label="岗位(工种)：">
          <el-input class="zwx-input" v-model="msForm.jobNameLike" placeholder="请输入" @keyup.enter.native="search()" clearable />
        </el-form-item>
        <el-form-item label="危害因素：">
          <el-input class="zwx-input" v-model="msForm.involvedHazardsLike" placeholder="请输入" @keyup.enter.native="search()" clearable />
        </el-form-item>
        <el-form-item label="状态：">
          <el-checkbox-group class="zwx-checkbox-group" v-model="msForm.ifEnables">
            <el-checkbox class="zwx-checkbox" :label="true">启用</el-checkbox>
            <el-checkbox class="zwx-checkbox" :label="false">停用</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-button class="zwx-button zwx-button-icontext-28" type="primary" icon="el-icon-search" @click="queryInquire()">查询</el-button>
        <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-plus" @click="addDetail">添加</el-button>
        <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-download" @click="deptStationDialogOpen">导入</el-button>
      </div>
    </el-form>
    <!-- 列表展示部分 -->
    <el-table class="zwx-table" style="width: 100%;" row-key="rid" :data="tableList" border stripe tooltip-effect="light">
      <el-table-column prop="jobName" label="岗位(工种)" width="200" header-align="center" align="left"></el-table-column>
      <el-table-column label="是否接害" width="90" header-align="center" align="center">
        <template slot-scope="scope">
          {{ scope.row.exposureHazards ? '是' : '否' }}
        </template>
      </el-table-column>
      <el-table-column prop="involvedHazards" label="危害因素" width="300" header-align="center" align="left" show-overflow-tooltip></el-table-column>
      <el-table-column prop="equippedPpe" label="防护用品" width="300" header-align="center" align="left" show-overflow-tooltip></el-table-column>
      <el-table-column prop="ifEnable" label="状态" width="90" header-align="center" align="center">
        <template slot-scope="scope">
          <div class="zwx-circle-text zwx-circle-normal" v-if="scope.row.ifEnable">启用</div>
          <div class="zwx-circle-text zwx-circle-finish" v-else>停用</div>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" min-width="200" header-align="left" align="left">
        <template slot-scope="scope">
          <el-button class="zwx-button zwx-button-text-26" type="text" icon="el-icon-top-right" @click="getDetail(scope.row)">编辑</el-button>
          <el-button class="zwx-button zwx-button-text-26" type="text" @click="copyAdd(scope.row)">复制添加</el-button>
          <el-button class="zwx-button zwx-button-text-26" :class="scope.row.ifEnable ? 'zwx-button-danger' : ''" type="text" @click="$system.msgbox(scope.row, '提示', scope.row.ifEnable ? '确定要停用吗？' : '确定要启用吗？', '确定', '取消', enableOrDisableJob)">
            {{ scope.row.ifEnable ? '停用' : '启用' }}
          </el-button>
          <el-button class="zwx-button zwx-button-text-26 zwx-button-danger" type="text" @click="$system.msgbox(scope.row, '提示', scope.row.ifEnable ? '确定要删除吗？' : '确定要启用吗？', '确定', '取消', deleteByRid)"><span style="color: #DD3838;">删除</span></el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 部门车间、岗位工种导入弹框 -->
    <data-import ref="deptStationDialogRef" :title="deptStationTitle" accept=".xls,.xlsx,.XLS,.XLSX" :ifshow-upload="$zwxBase.verifyIsBlank(deptStationDialog.annexName)" :size="50 * 1024 * 1024" sizeInfo="50M" :action="api + actionApi" :paramData="deptStationDialog.paramData" :successCount="deptStationDialog.successCount" :errorCount="deptStationDialog.errorCount" :ifShowErrorMsg="$zwxBase.verifyIsNotBlank(deptStationDialog.exportErrorUid)" :determineBtnDisabled="deptStationDialog.determineBtnDisabled" :errorMsg="deptStationDialog.errorMsg" @beforeUpload="fileBeforeUpload" @tempDownload="tempDownload" @deletionFile="deletionFile" @fileSubmitSuccess="fileSubmitSuccess" @fileSubmitError="fileSubmitError" @change="clickFileUpload" @determine="determine" @errorReportDownload="errorReportDownload" @cancel="cancel" @close="close"></data-import>
  </div>
</template>
<script>
export default {
  name: 'JobInfo',
  data() {
    return {
      users: this.$store.state.users,
      api: this.$store.state.api,
      employerUuid: '',
      msForm: {
        jobNameLike: '',
        involvedHazardsLike: '',
        ifEnables: [true],
      },
      tableList: [],
      editCode: '',
      rulesNewJobAdd: {
        jobName: [{ required: true, message: '请输入岗位名称', trigger: 'blur' }],
        exposureHazards: [{ required: true, message: '请选择是否接害', trigger: ['change', 'blur'] }],
        hazardsNameDetail: [{ required: true, message: '请输入名称', trigger: ['change', 'blur'] }],
        // jobPpeStr: [{ validator: this.validateJobPpeStr, trigger: ['blur', 'change'] }],
      },
      actionApi: '/zky/employer/importJobInfoExcel-0',
      deptStationDialog: {
        annexName: '',
        successCount: 0,
        errorCount: 0,
        exportErrorUid: '',
        determineBtnDisabled: false,
        errorMsg: '',
        paramData: {
          userUid: this.$store.state.users.uid,
          unitId: '',
          uuid: '',
        },
      },
      deptStationTitle: '岗位（工种）',
    }
  },
  computed: {},
  watch: {},
  activated() {
    // 二级页路由跳转回一级页判断是否需要刷新页面
    if (this.$route.params.fresh == true) {
      this.queryInquire()
    }
  },
  created() {},
  mounted() {
    if (this.$zwxBase.verifyIsNotBlank(this.$route.query.employerUuid)) {
      this.employerUuid = this.$route.query.employerUuid
    }
    this.queryInquire()
  },
  methods: {
    deptStationDialogOpen() {
      this.$refs.deptStationDialogRef.show(true)
    },
    fileBeforeUpload(val) {
      if (!val) this.deptStationDialog.determineBtnDisabled = true
    },
    tempDownload() {
      let apiWay = '/zky/employer/downloadJobImportTemplate-0'
      this.$system.downloadUrlFile(this.deptStationTitle + '导入模板.xls', this.api + apiWay + '?userUid=' + this.$store.state.users.uid + '&employerUuid=' + this.employerUuid)
    },
    /**
     * 删除文件
     * */
    deletionFile() {
      this.deptStationDialog.annexName = ''
      this.deptStationDialog.exportErrorUid = ''
      this.deptStationDialog.successCount = 0
      this.deptStationDialog.errorCount = 0
      this.deptStationDialog.determineBtnDisabled = false
    },
    fileSubmitSuccess(data) {
      this.$emit('loading', false)
      if (data.type === '00') {
        this.deptStationDialog.successCount = data.successCount || 0
        this.deptStationDialog.errorCount = data.errorCount || 0
        this.deptStationDialog.exportErrorUid = data.exportErrorUid || ''
        if (this.$zwxBase.verifyIsBlank(this.deptStationDialog.exportErrorUid)) {
          this.$refs.deptStationDialogRef.show(false)
          this.$system.notify('成功', '导入成功' + this.deptStationDialog.successCount + '条', 'success')
          // 刷新列表
          this.queryInquire()
        }
      } else {
        this.$system.notify('错误', data.mess, 'error')
        this.deptStationDialog.exportErrorUid = data.exportErrorUid || ''
      }
    },
    fileSubmitError(data) {
      this.$emit('loading', false)
      this.$system.notify('错误', data.mess, 'error')
      this.deptStationDialog.exportErrorUid = data.exportErrorUid || ''
    },
    /**
     * 上传文件
     * */
    clickFileUpload(showList) {
      showList.forEach(item => {
        let flag = this.deptStationDialog.annexName === item
        if (flag) {
          return
        }
        this.deptStationDialog.annexName = item
      })
      if (this.$zwxBase.verifyIsNotBlank(this.deptStationDialog.annexName)) {
        this.deptStationDialog.errorMsg = ''
      }
    },
    /**
     * 导入弹框确认
     * */
    determine() {
      if (this.$zwxBase.verifyIsBlank(this.deptStationDialog.annexName)) {
        this.deptStationDialog.errorMsg = '请上传文件'
        return
      } else {
        this.deptStationDialog.errorMsg = ''
      }
      this.deptStationDialog.determineBtnDisabled = true
      let apiWay = '/zky/employer/jobInfoImportUid-1'
      this.$emit('loading', true)
      this.$system.postJson(
        this.api + apiWay,
        null,
        true,
        true,
        data => {
          if (data.type === '00') {
            this.deptStationDialog.paramData.uuid = data.uid
            this.deptStationDialog.paramData.employerUuid = this.employerUuid
            this.$refs.deptStationDialogRef.fileSubmit()
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    errorReportDownload() {
      let apiWay = '/zky/employer/exportJobInfoErrorExcel-0'
      this.$system.downloadUrlFile(this.deptStationTitle + '错误报告.xls', this.api + apiWay + '?exportErrorUid=' + this.deptStationDialog.exportErrorUid)
    },
    cancel() {
      this.$refs.deptStationDialogRef.show(false)
    },
    close() {
      this.deptStationTitle = '岗位（工种）'
      this.deptStationDialog.annexName = ''
      this.deptStationDialog.uid = ''
      this.deptStationDialog.successCount = 0
      this.deptStationDialog.errorCount = 0
      this.deptStationDialog.exportErrorUid = ''
      this.deptStationDialog.paramData.unitId = ''
      this.deptStationDialog.paramData.uuid = ''
      this.deptStationDialog.determineBtnDisabled = false
      this.deptStationDialog.errorMsg = ''
      // 刷新列表
      this.queryInquire()
    },
    /**
     * 查询
     */
    queryInquire() {
      let data = {
        employerUuid: this.employerUuid,
        jobNameLike: this.msForm.jobNameLike,
        involvedHazardsLike: this.msForm.involvedHazardsLike,
        ifEnable: this.msForm.ifEnables.length === 1 ? this.msForm.ifEnables[0] : null,
      }
      this.$emit('loading', true)
      this.$system.get(
        this.api + '/zky/employer/getJobInfoListByEmployerId-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type === '00') {
            this.tableList = data.jobInfoList
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    /**
     * 添加
     */
    addDetail() {
      this.$router.push({ name: 'JobInfoDetail', params: { employerUuid: this.employerUuid, type: 'add',entrance:'job', } })
    },
    /**
     * 复制添加
     */
    copyAdd(row) {
      this.$router.push({ name: 'JobInfoDetail', params: { rid: row.rid, employerUuid: this.employerUuid, type: 'copyAdd',entrance:'job',} })
    },
    /**
     * 编辑
     */
    getDetail(row) {
      this.$router.push({ name: 'JobInfoDetail', params: { rid: row.rid, employerUuid: this.employerUuid, type: 'edit',entrance:'job', } })
    },
    /**
     * 删除岗位
     */
    deleteByRid(row) {
      let data = {
        rid: row.rid, //岗位id
      }
      this.$emit('loading', true)
      this.$system.postJson(
        this.api + '/zky/employer/deleteJob-1',
        data,
        true,
        true,
        data => {
          if (data.type === '00') {
            this.$system.notify('成功', data.mess, 'success')
            this.queryInquire()
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
          this.$emit('loading', false)
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    //修改岗位工种启用状态
    enableOrDisableJob(row) {
      let data = {
        rid: row.rid,
        ifEnable: !row.ifEnable,
      }
      this.$emit('loading', true)
      this.$system.post(
        this.api + '/zky/employer/enableOrDisableJob-1',
        data,
        true,
        true,
        data => {
          if (data.type === '00') {
            this.$system.notify('成功', data.mess, 'success')
            this.queryInquire()
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$notify.error({ title: '错误', message: data.mess })
          }
          this.$emit('loading', false)
        },
        this.error
      )
    },
  },
}
</script>

<style lang="less" scoped>
.job-info-main-page {
  .condition-row {
    max-width: unset !important;
  }
}
.import-dept {
  position: fixed;
  left: 105px;
  top: 9px;
}
.department-station {
  display: flex;
  flex-direction: row;
  flex: 1;
  flex-basis: auto;
  box-sizing: border-box;

  .station-list {
    width: 450px;
    height: calc(100vh - 44px);
    border-right: 1px solid #e6e6e6;

    .station-list-title {
      height: 40px;
      padding-left: 20px;
      border-bottom: 1px solid #e6e6e6;
      font-size: 16px;
      text-align: left;
      color: #000000;
      line-height: 40px;
    }

    .station-search {
      padding: 12px 0px 12px 12px;
      border-bottom: 1px solid #e6e6e6;
    }
  }

  .equipment-detail {
    width: calc(100% - 450px);

    .header-title {
      padding: 6px 20px;
      font-size: 16px;

      .zwx-button {
        margin-left: 24px;
      }
    }
  }
}
</style>
<style lang="less">
.el-tooltip__popper {
  background: white !important; /*背景色  !important优先级*/
  color: #0f0101 !important; /*字体颜色*/
  opacity: 1 !important; /*背景色透明度*/
  max-width: 300px;
}
</style>
