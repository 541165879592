<template>
  <div class="shelve_goods_management">
    <el-form class="zwx-form" key="trainingCourseForm" ref="trainingCourseForm" :model="trainingCourseForm" label-position="right" @submit.native.prevent>
      <el-form-item label="培训班名称：" label-width="84px">
        <el-input class="zwx-input" v-model.trim="trainingCourseForm.trainingActivityName" style="width: 160px !important" placeholder="" clearable maxlength="50" />
      </el-form-item>
      <!-- <el-form-item label="考试类型：" label-width="100px">
        <el-select v-model="trainingCourseForm.examType" placeholder="请选择" class="zwx-select" popper-class="zwx-select-popper" >
          <el-option label="不限" :value="''"></el-option>
          <el-option label="线上考试" :value="1"></el-option>
          <el-option label="线下考试" :value="2"></el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="报名状态：" label-width="100px">
        <el-select v-model="trainingCourseForm.registStatus" placeholder="请选择" class="zwx-select" popper-class="zwx-select-popper" >
          <el-option label="不限" :value="''"></el-option>
          <el-option label="未开始" :value="0"></el-option>
          <el-option label="进行中" :value="1"></el-option>
          <el-option label="已截止" :value="-1"></el-option>
        </el-select>
      </el-form-item>
      <div>
        <el-form-item label="培训班状态：" label-width="84px">
          <el-select v-model="trainingCourseForm.ifEnable" placeholder="请选择" class="zwx-select" popper-class="zwx-select-popper" >
            <el-option label="不限" :value="null"></el-option>
            <el-option label="启用" :value="true"></el-option>
            <el-option label="停用" :value="false"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="ifShowOff" label="线下培训时间：" label-width="100px">
          <el-date-picker class="zwx-date-picker" popper-class="zwx-date-picker-popper" style="width: 160px !important" v-model="trainingCourseForm.offLineStartTime" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="$validate.noBigDate($data['trainingCourseForm'], 'offLineEndTime', true)" placeholder="开始日期" />
          -
          <el-date-picker class="zwx-date-picker" popper-class="zwx-date-picker-popper" style="width: 160px !important" v-model="trainingCourseForm.offLineEndTime" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="$validate.noSmallDate($data['trainingCourseForm'], 'offLineStartTime', true)" placeholder="结束日期" />
        </el-form-item>
      </div>
    </el-form>
    <div class="independent-button-row">
      <el-button class="zwx-button zwx-button-icontext-28" type="primary" icon="el-icon-search" @click="queryTrainingCourse(1)">查询</el-button>
      <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-plus" @click="add">添加</el-button>
    </div>
    <!-- 列表展示部分 -->
    <el-table class="zwx-table" row-key="rid" :data="tableList" border stripe tooltip-effect="light">
      <el-table-column prop="trainingActivityName" label="培训班名称" min-width="220" header-align="center" align="left"></el-table-column>
      <!-- <el-table-column prop="examType" label="考试类型" width="90" header-align="center" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.examType == 1">线上考试</span>
          <span v-if="scope.row.examType == 2">线下考试</span>
        </template>
      </el-table-column> -->
      <el-table-column prop="price" label="报名时间" width="340" header-align="center" align="center">
        <template slot-scope="scope">
          <span>
            {{ scope.row.registStartTime | time }}
          </span>
          至
          <span>
            {{ scope.row.registEndTime | time }}
          </span>
        </template>
      </el-table-column>
      <el-table-column v-if="ifShowOff" prop="salePrice" label="线下培训时间" width="340" header-align="center" align="center">
        <template slot-scope="scope">
          <div>
            <span>
              {{ scope.row.offLineStartTime | time }}
            </span>
            至
            <span>
              {{ scope.row.offLineEndTime | time }}
            </span>
          </div>
          <!-- <div v-else>-</div> -->
        </template>
      </el-table-column>
      <el-table-column prop="registStatus" label="线上报名状态" width="120" header-align="center" align="center">
        <template slot-scope="scope">
          <span class="zwx-circle-text zwx-circle-text:before" :class="getClass(scope.row.registStatus)">{{ registStatusMap[scope.row.registStatus] }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="ifEnable" label="培训班状态" width="100" header-align="center" align="center">
        <template slot-scope="scope">
          <template v-if="scope.row.ifEnable">
            <div class="zwx-circle-text zwx-circle-normal">启用</div>
          </template>
          <template v-if="!scope.row.ifEnable">
            <div class="zwx-circle-text zwx-circle-underway">停用</div>
          </template>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="createTime" label="创建时间" width="180" header-align="center" align="center"></el-table-column> -->
      <el-table-column fixed="right" label="操作" min-width="200" header-align="left" align="left">
        <template slot-scope="scope">
          <el-button class="zwx-button zwx-button-text-26" type="text" v-if="!scope.row.ifEnable" icon="el-icon-arrow-right" @click="edit(scope.row)">编辑</el-button>
          <el-button class="zwx-button zwx-button-text-26" type="danger" v-if="scope.row.registStatus == 0 && scope.row.ifEnable" @click="deleteList(scope.row)">删除</el-button>
          <el-button class="zwx-button zwx-button-text-26" type="text" icon="el-icon-arrow-right" v-if="registStatusMap[scope.row.registStatus] == '进行中' || registStatusMap[scope.row.registStatus] == '已截止'" @click="detail(scope.row)">查看</el-button>
          <!-- <el-button class="zwx-button zwx-button-text-26" type="text" icon="el-icon-arrow-right" @click="detail(scope.row)">查看</el-button> -->
          <!-- <el-button class="zwx-button zwx-button-text-26" type="text" icon="el-icon-arrow-right" @click="detail(scope.row)">查看2</el-button> -->
          <el-button class="zwx-button zwx-button-text-26" type="text" icon="el-icon-arrow-right" v-if="registStatusMap[scope.row.registStatus] == '进行中' || registStatusMap[scope.row.registStatus] == '已截止'" @click="studentSet(scope.row)">学员管理</el-button>
          <el-button class="zwx-button zwx-button-text-26" type="danger" v-if="scope.row.ifEnable" @click="enableChange(scope.row)">停用</el-button>
          <el-button class="zwx-button zwx-button-text-26" type="text" v-if="!scope.row.ifEnable" @click="enableChange(scope.row)">启用</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页器部分 -->
    <base-pagination :parentPage="trainingCourseForm.currentPage" :pageSize="trainingCourseForm.pageSize" :total="trainingCourseForm.total" @currentChange="queryTrainingCourse" />
    <!-- 签到码 -->
    <el-dialog :title="dialogTitle" :visible.sync="signInQrCodeVisible" class="bindQrCodeDialog">
      <img style="width: 260px; margin: 10px 40px 0px" :src="file + bindQrCodePath" />
      <div style="display: flex; justify-content: center; padding: 0 20px 10px">
        <el-button @click="downloadSignInQrCode">下载</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'TrainingManagement',
  filters: {
    time: function (value) {
      if (!value) return ''
      return Vue.prototype.$system.formatDate(value, 'YYYY-MM-DD HH:mm')
    },
  },
  data() {
    return {
      api: this.$store.state.api,
      file: this.$store.state.file,
      trainingCourseForm: {
        trainingActivityName: '', //培训班名称
        examType: '', //考试状态
        registStatus: '', //线上报名状态
        ifEnable: true, //培训班状态
        offLineStartTime: '', //线下培训开始时间
        offLineEndTime: '', //线下培训结束时间
        currentPage: 1,
        pageSize: 18,
        total: 0,
      },
      registStatusMap: { '-1': '已截止', 0: '未开始', 1: '进行中' },
      tableList: [],
      signInQrCodeVisible: false,
      bindQrCodePath: '',
      bindQrCodeName: '',
      dialogTitle: '',
      ifShowOff: true,
      unitNature: null,
    }
  },
  activated() {
    if (this.$route.params.fresh) this.queryTrainingCourse(1)
  },
  created() {
    this.ifShowOff = window.top.vm.$store.state.config.ifTrainingClassOffline
  },
  mounted() {
    this.queryTrainingCourse(1)
  },
  methods: {
    getClass(status) {
      let val = ''
      switch (status) {
        case -1:
          val = 'zwx-circle-finish zwx-circle-finish:before'
          break
        case 0:
          val = 'zwx-circle-normal zwx-circle-normal:before'
          break
        case 1:
          val = 'zwx-circle-underway zwx-circle-underway:before'
          break
        default:
          break
      }
      return val
    },
    queryTrainingCourse(currentPage) {
      this.trainingCourseForm.currentPage = currentPage
      let data = {
        ...this.trainingCourseForm,
      }
      this.$emit('loading', true)
      this.$system.get(
        this.api + '/training/platform/getTrainingActivityMainList-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type === '00') {
            this.tableList = data.trainingActivityMainList
            this.trainingCourseForm.pageSize = data.pageSize
            this.trainingCourseForm.total = data.trainingActivityMainCount
          }
        },
        () => {
          this.$emit('loading', false)
          this.$notify.error({ title: '错误', message: data.mess })
        }
      )
    },
    add() {
      this.$router.push({ name: 'TrainingManagementEdit', params: { type: 'add' } })
    },
    edit(row) {
      let data = {
        uuid: row.uuid,
      }
      this.$system.get(
        this.api + '/training/platform/getActivityMainIfRegister-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type === '00') {
            if (data.ifRegister) {
              this.$notify.error({ title: '错误', message: '该培训班已经有人报名，禁止编辑' })
            } else {
              this.$router.push({ name: 'TrainingManagementEdit', params: { row: row, type: 'edit' } })
            }
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$notify.error({ title: '错误', message: data.mess })
          }
        },
        () => {
          this.$emit('loading', false)
          this.$notify.error({ title: '错误', message: '网络连接失败' })
        }
      )
      // this.$system.msgbox('', '提示', row.enrollSum == 0 ? '确认编辑？' : '该培训班已经有人报名，仅可以修改基本信息，确认编辑？', '确定', '取消', () => {
      //   this.$router.push({ name: 'TrainingManagementEdit', params: { row: row, type: 'edit' } })
      // })
    },
    // 删除
    deleteList(row) {
      let data = {
        uuid: row.uuid,
      }
      this.$system.msgbox('', '提示', '是否确认删除？', '确定', '取消', () => {
        this.$system.post(
          this.api + '/training/platform/deleteTrainingActivityMain-1',
          data,
          true,
          true,
          data => {
            this.$emit('loading', false)
            if (data.type === '00') {
              this.$system.notify('成功', '删除成功', 'success')
              this.queryTrainingCourse(this.trainingCourseForm.currentPage)
            } else if (data.type === '99') {
              this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
            } else {
              this.$notify.error({ title: '错误', message: data.mess })
            }
          },
          () => {
            this.$emit('loading', false)
            this.$notify.error({ title: '错误', message: '网络连接失败' })
          }
        )
      })
    },
    // 查看详情
    detail(row) {
      let ifShowQd = (this.registStatusMap[row.registStatus] == '进行中' || this.registStatusMap[row.registStatus] == '已截止') && row.ifEnable
      let ifShowBm = this.registStatusMap[row.registStatus] == '进行中' && row.ifEnable
      this.$router.push({ name: 'TrainingManagementDetail', params: { row: row, type: '1', ifShowQd, ifShowBm } })
    },
    // 学员管理
    studentSet(row) {
      let ifShowQd = (this.registStatusMap[row.registStatus] == '进行中' || this.registStatusMap[row.registStatus] == '已截止') && row.ifEnable
      let ifShowBm = this.registStatusMap[row.registStatus] == '进行中' && row.ifEnable
      this.$router.push({ name: 'TrainingManagementDetail', params: { row: row, type: '2', ifShowQd, ifShowBm } })
    },
    // 启用停用
    enableChange(row) {
      let data = {
        uuid: row.uuid,
        ifEnable: !row.ifEnable,
      }
      this.$emit('loading', true)
      if (row.ifEnable) {
        this.$system.msgbox('', '提示', '确认是否停用？', '确定', '取消', () => {
          this.$system.get(
            this.api + '/training/platform/updateTdTrainingActivityMainByEnable-1',
            data,
            true,
            true,
            data => {
              this.$emit('loading', false)
              if (data.type === '00') {
                this.queryTrainingCourse(this.trainingCourseForm.currentPage)
              }
            },
            data => {
              this.$emit('loading', false)
              this.$system.error({ title: '错误', message: data.mess })
            }
          )
        })
        this.$emit('loading', false)
      } else {
        this.$system.get(
          this.api + '/training/platform/updateTdTrainingActivityMainByEnable-1',
          data,
          true,
          true,
          data => {
            this.$emit('loading', false)
            if (data.type === '00') {
              this.queryTrainingCourse(this.trainingCourseForm.currentPage)
            }
          },
          () => {
            this.$emit('loading', false)
            this.$system.error({ title: '错误', message: data.mess })
          }
        )
      }
    },
    downloadSignInQrCode() {
      if (this.$zwxBase.verifyIsNotBlank(this.bindQrCodePath)) {
        this.$system.downloadUrlFile(this.bindQrCodeName, this.$store.state.api + this.bindQrCodePath)
      }
    },
  },
}
</script>

<style lang="less" scoped>
.bindQrCodeDialog /deep/.el-dialog {
  width: fit-content;
  min-width: auto !important;
}
</style>
