var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "job-info-main-page" },
    [
      _c(
        "el-form",
        {
          key: "msForm",
          ref: "msForm",
          staticClass: "zwx-form",
          attrs: { model: _vm.msForm, "label-position": "right" },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "self-adapting-condition" },
            [
              _c(
                "el-form-item",
                { attrs: { label: "岗位(工种)：" } },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    attrs: { placeholder: "请输入", clearable: "" },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.search()
                      }
                    },
                    model: {
                      value: _vm.msForm.jobNameLike,
                      callback: function($$v) {
                        _vm.$set(_vm.msForm, "jobNameLike", $$v)
                      },
                      expression: "msForm.jobNameLike"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "危害因素：" } },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    attrs: { placeholder: "请输入", clearable: "" },
                    nativeOn: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.search()
                      }
                    },
                    model: {
                      value: _vm.msForm.involvedHazardsLike,
                      callback: function($$v) {
                        _vm.$set(_vm.msForm, "involvedHazardsLike", $$v)
                      },
                      expression: "msForm.involvedHazardsLike"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "状态：" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      staticClass: "zwx-checkbox-group",
                      model: {
                        value: _vm.msForm.ifEnables,
                        callback: function($$v) {
                          _vm.$set(_vm.msForm, "ifEnables", $$v)
                        },
                        expression: "msForm.ifEnables"
                      }
                    },
                    [
                      _c(
                        "el-checkbox",
                        { staticClass: "zwx-checkbox", attrs: { label: true } },
                        [_vm._v("启用")]
                      ),
                      _c(
                        "el-checkbox",
                        {
                          staticClass: "zwx-checkbox",
                          attrs: { label: false }
                        },
                        [_vm._v("停用")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-button",
                {
                  staticClass: "zwx-button zwx-button-icontext-28",
                  attrs: { type: "primary", icon: "el-icon-search" },
                  on: {
                    click: function($event) {
                      return _vm.queryInquire()
                    }
                  }
                },
                [_vm._v("查询")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "zwx-button zwx-button-icontext-28",
                  attrs: { icon: "el-icon-plus" },
                  on: { click: _vm.addDetail }
                },
                [_vm._v("添加")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "zwx-button zwx-button-icontext-28",
                  attrs: { icon: "el-icon-download" },
                  on: { click: _vm.deptStationDialogOpen }
                },
                [_vm._v("导入")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-table",
        {
          staticClass: "zwx-table",
          staticStyle: { width: "100%" },
          attrs: {
            "row-key": "rid",
            data: _vm.tableList,
            border: "",
            stripe: "",
            "tooltip-effect": "light"
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "jobName",
              label: "岗位(工种)",
              width: "200",
              "header-align": "center",
              align: "left"
            }
          }),
          _c("el-table-column", {
            attrs: {
              label: "是否接害",
              width: "90",
              "header-align": "center",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(scope.row.exposureHazards ? "是" : "否") +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "involvedHazards",
              label: "危害因素",
              width: "300",
              "header-align": "center",
              align: "left",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "equippedPpe",
              label: "防护用品",
              width: "300",
              "header-align": "center",
              align: "left",
              "show-overflow-tooltip": ""
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "ifEnable",
              label: "状态",
              width: "90",
              "header-align": "center",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.ifEnable
                      ? _c(
                          "div",
                          { staticClass: "zwx-circle-text zwx-circle-normal" },
                          [_vm._v("启用")]
                        )
                      : _c(
                          "div",
                          { staticClass: "zwx-circle-text zwx-circle-finish" },
                          [_vm._v("停用")]
                        )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              fixed: "right",
              label: "操作",
              "min-width": "200",
              "header-align": "left",
              align: "left"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        staticClass: "zwx-button zwx-button-text-26",
                        attrs: { type: "text", icon: "el-icon-top-right" },
                        on: {
                          click: function($event) {
                            return _vm.getDetail(scope.row)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "zwx-button zwx-button-text-26",
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.copyAdd(scope.row)
                          }
                        }
                      },
                      [_vm._v("复制添加")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "zwx-button zwx-button-text-26",
                        class: scope.row.ifEnable ? "zwx-button-danger" : "",
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.$system.msgbox(
                              scope.row,
                              "提示",
                              scope.row.ifEnable
                                ? "确定要停用吗？"
                                : "确定要启用吗？",
                              "确定",
                              "取消",
                              _vm.enableOrDisableJob
                            )
                          }
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(scope.row.ifEnable ? "停用" : "启用") +
                            " "
                        )
                      ]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass:
                          "zwx-button zwx-button-text-26 zwx-button-danger",
                        attrs: { type: "text" },
                        on: {
                          click: function($event) {
                            return _vm.$system.msgbox(
                              scope.row,
                              "提示",
                              scope.row.ifEnable
                                ? "确定要删除吗？"
                                : "确定要启用吗？",
                              "确定",
                              "取消",
                              _vm.deleteByRid
                            )
                          }
                        }
                      },
                      [
                        _c("span", { staticStyle: { color: "#DD3838" } }, [
                          _vm._v("删除")
                        ])
                      ]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("data-import", {
        ref: "deptStationDialogRef",
        attrs: {
          title: _vm.deptStationTitle,
          accept: ".xls,.xlsx,.XLS,.XLSX",
          "ifshow-upload": _vm.$zwxBase.verifyIsBlank(
            _vm.deptStationDialog.annexName
          ),
          size: 50 * 1024 * 1024,
          sizeInfo: "50M",
          action: _vm.api + _vm.actionApi,
          paramData: _vm.deptStationDialog.paramData,
          successCount: _vm.deptStationDialog.successCount,
          errorCount: _vm.deptStationDialog.errorCount,
          ifShowErrorMsg: _vm.$zwxBase.verifyIsNotBlank(
            _vm.deptStationDialog.exportErrorUid
          ),
          determineBtnDisabled: _vm.deptStationDialog.determineBtnDisabled,
          errorMsg: _vm.deptStationDialog.errorMsg
        },
        on: {
          beforeUpload: _vm.fileBeforeUpload,
          tempDownload: _vm.tempDownload,
          deletionFile: _vm.deletionFile,
          fileSubmitSuccess: _vm.fileSubmitSuccess,
          fileSubmitError: _vm.fileSubmitError,
          change: _vm.clickFileUpload,
          determine: _vm.determine,
          errorReportDownload: _vm.errorReportDownload,
          cancel: _vm.cancel,
          close: _vm.close
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }